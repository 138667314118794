<template>
  <dropdown v-if="user && workspaces && workspaces.length > 1" ref="dropdown"
    dropdown-class="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 z-50"
    dusk="workspace-dropdown">
    <template v-if="workspace" #trigger="{ toggle }">
      <div class="flex items-center cursor group" role="button" @click.stop="toggle()">
        <span
          class="inline-flex items-center justify-center size-[36px] text-sm rounded-lg bg-gray-500 font-semibold text-white leading-none">
          {{ extractInitials(workspace.name) }}
        </span>
        <p class="hidden font-semibold lg:block max-w-10 truncate ml-2 text-gray-800 dark:text-gray-200">
          {{ workspace.name }}
        </p>
      </div>
    </template>

    <template v-for="worksp in workspaces" :key="worksp.id">
      <a href="#"
        class="px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 flex items-center"
        :class="{ 'bg-blue-100 dark:bg-blue-900': workspace?.id === worksp?.id }"
        @click.prevent="switchWorkspace(worksp)">
        <div class="rounded-full h-8 w-8 flex-shrink-0" role="button">
          <span
            class="inline-flex items-center justify-center size-[36px] text-sm rounded-lg bg-gray-500 font-semibold text-white leading-none">
            {{ extractInitials(worksp.name) }}
          </span>
        </div>
        <p class="ml-4 truncate">{{ worksp.name }}</p>
      </a>
    </template>
  </dropdown>
</template>

<script>
import { computed } from 'vue'
import Dropdown from '~/components/global/Dropdown.vue'

export default {

  name: 'WorkspaceDropdown',
  components: {
    Dropdown
  },

  setup() {
    const authStore = useAuthStore()
    const formsStore = useFormsStore()
    const workspacesStore = useWorkspacesStore()
    const workSpaceStore = useWorkSpaceStore()
    return {
      formsStore,
      workspacesStore,
      workSpaceStore,
      user: computed(() => authStore.user),
    }
  },

  computed: {
    workspace() {
      return this.workSpaceStore.currentWorkSpace
    },
    workspaces() {
      return this.workSpaceStore.workspaces
    }
  },

  watch: {
  },

  mounted() {
  },

  methods: {
    switchWorkspace(workspace) {

      this.workSpaceStore.setCurrentWorkSpace(workspace)

      this.formsStore.resetState()

      this.formsStore.loadAll(workspace.id)

      const router = useRouter()

      const route = useRoute()

      if (route.name !== 'home') {
        router.push({ name: 'home' })
      }

      // this.formsStore.loadAll(workspace.id)
    },
    isUrl(str) {
      try {
        new URL(str)
      } catch (_) {
        return false
      }
      return true
    },
    extractInitials(name) {
      // Split the name into words
      const words = name.split(" ");

      // Get the first letter of the first word (word at index 0)
      let initials = words[0][0].toUpperCase();

      // If there's more than one word, append the first letter of the last word
      if (words.length > 1) {
        initials += words[words.length - 1][0].toUpperCase();
      }

      // Return the initials
      return initials;
    }
  }
}
</script>
